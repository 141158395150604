// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import { divider } from './index.module.scss';

type Props = {
  className?: string | null,
}

const SubNavSnippetDivider = ({ className = null }: Props): Element<'span'> => (
  <span className={classNames(className, divider)}>|</span>
);

export default SubNavSnippetDivider;
