/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Card, CardBody, Row, Col,
} from 'reactstrap';
import ComponentSummary from '../../support/ComponentSummary/index.js';
import ComponentSelector from '#components/pages/Components/support/ComponentSelector/index.js';

type Props = {|
  autofilled: boolean,
  fields: {
    get: Function,
    forEach: Function,
  },
  form: string,
  handleAdd: Function,
|}

const render = ({
  autofilled,
  fields,
  handleAdd,
}: Props): Element<'div'> => {
  const [selected, setSelected] = useState('');

  // Use field array data to determine whether the current component is in the model
  let componentInModel = false;

  fields.forEach((field, index) => {
    const { componentUuid } = fields.get(index);
    if (componentUuid === selected) {
      componentInModel = true;
    }
  });

  const handleAddComponent = handleAdd(selected);

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h2>Add Component</h2>
            </Col>
            <Col>
              <span className="float-right">
                <Button id="topCloseButton" tag={Link} color="link" to="..">Close</Button>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <ComponentSelector
                onSelect={setSelected}
                name="modelsComponentSelector"
                selectedUuid={selected}
                collapsable
                showIncludeArchivedToggle={false}
              />
            </Col>
          </Row>
          {selected && (
            <Card>
              <CardBody>
                <ComponentSummary
                  closeUrl=".."
                  componentUuid={selected}
                />
              </CardBody>
            </Card>
          )}
          {
            selected && componentInModel && (
            <Row>
              <Col>
                <span className="float-right text-muted"> This component is already in your model</span>
              </Col>
            </Row>
            )
          }
          <Row className="pt-3">
            <Col>
              <Button id="bottomCloseButton" tag={Link} to="..">Back to Components Table</Button>
            </Col>
            <Col>
              {
                selected && (
                  <Button
                    id="addComponentButton"
                    className="float-right"
                    color="primary"
                    disabled={autofilled || componentInModel}
                    onClick={handleAddComponent}
                  >
                    Add Component To Model
                  </Button>
                )
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default render;
