// @flow
import type { Element } from 'react';
import React from 'react';
import { Snippet, SnippetDivider } from '#components/support/layout/SubNav/index.js';
import SaveStatusItem from '#components/pages/Components/Component/SubNav/Snippet/SaveStatusItem/index.js';
import ValidationItem from '#components/pages/Components/Component/SubNav/Snippet/ValidationItem/index.js';

type Props = {
  show: boolean,
};
const ComponentSnippet = ({ show }: Props): Element<typeof Snippet> | null => (
  show
    ? (
      <Snippet>
        <SaveStatusItem />
        <SnippetDivider />
        <ValidationItem />
      </Snippet>
    ) : null
);

export default ComponentSnippet;
