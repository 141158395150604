// @flow
import type { Element } from 'react';

import React, { useState } from 'react';
// eslint-disable-next-line import/extensions
import { GoCheck } from 'react-icons/go';
import { SnippetItem, SnippetItemIcon, SnippetItemText } from '#components/support/layout/SubNav/index.js';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';

type Props = {
  handleSubmit: Function,
  isValid: boolean,
};

const ValidationItem = ({ handleSubmit, isValid }: Props): Element<typeof SnippetItem> => {
  const [validating, setValidating] = useState(false);

  const handleClick = (e) => {
    setValidating(true);
    handleSubmit(e);
    setTimeout(() => {
      setValidating(false);
    }, 500);
  };

  if (isValid) {
    return (
      <SnippetItem>
        <SnippetItemText>Valid</SnippetItemText>
        <SnippetItemIcon icon={GoCheck} />
      </SnippetItem>
    );
  }
  return (
    <SnippetItem>
      <SubmitButton
        className="pl-0 pt-0 pb-0"
        submitting={validating}
        submittingText="Validating"
        onClick={handleClick}
        tag="a"
        color="link"
      >
        Validate
      </SubmitButton>
    </SnippetItem>
  );
};

export default ValidationItem;
