// @flow
import type { Element } from 'react';
import { Nav, NavItem } from 'reactstrap';
import React from 'react';
import * as ids from '@hbrisk/sp3-risk-model-support/components/sections/names.js';
import {
  Sidebar,
  SidebarNavLink,
} from '#components/support/layout/SidebarLayout/index.js';
import Badge from '#components/pages/Components/Component/Sidebar/Badge/index.js';
import * as paths from '#constants/pathStrings.js';

type Props = {
  top?: string,
  md: string,
  componentUuid: string,
};

const ComponentSidebar = ({ md, top = '111px', componentUuid }: Props): Element<typeof Sidebar> => (
  <Sidebar md={md} top={top}>
    <Nav vertical>
      <NavItem key={ids.GENERAL} id={`${ids.GENERAL}NavItem`}>
        <SidebarNavLink to={`${paths.GENERAL}`} className="pr-2">
          General
        </SidebarNavLink>
        <Badge section={ids.GENERAL} level="error" />
      </NavItem>
      <NavItem key={ids.FRAGILITY_INFO} id={`${ids.FRAGILITY_INFO}NavItem`}>
        <SidebarNavLink to={`${paths.FRAGILITY_INFO}`}>
          Fragility Info
        </SidebarNavLink>
        <Badge section={ids.FRAGILITY_INFO} level="error" />
      </NavItem>
      <NavItem key={ids.COMPONENT_GROUPING} id={`${ids.COMPONENT_GROUPING}NavItem`}>
        <SidebarNavLink to={`${paths.COMPONENT_GROUPING}`}>
          Component Grouping
        </SidebarNavLink>
        <Badge section={ids.COMPONENT_GROUPING} level="error" />
      </NavItem>
      <NavItem key={ids.CAPACITY_CALCULATION} id={`${ids.CAPACITY_CALCULATION}NavItem`}>
        <SidebarNavLink to={`${paths.CAPACITY_CALCULATION}`}>
          Capacity Calculation
        </SidebarNavLink>
        <Badge section={ids.CAPACITY_CALCULATION} level="error" />
      </NavItem>
      <NavItem key={ids.RECOVERY_TIME} id={`${ids.RECOVERY_TIME}NavItem`}>
        <SidebarNavLink to={`${paths.RECOVERY_TIME}`}>
          Recovery Time
        </SidebarNavLink>
        <Badge section={ids.RECOVERY_TIME} level="error" />
      </NavItem>
      <NavItem key={ids.SYSTEM_SPECIFIC_INFO} id={`${ids.SYSTEM_SPECIFIC_INFO}NavItem`}>
        <SidebarNavLink to={`${paths.SYSTEM_SPECIFIC_INFO}`}>
          System Specific Info
        </SidebarNavLink>
        <Badge section={ids.SYSTEM_SPECIFIC_INFO} level="error" />
      </NavItem>
      <NavItem key={ids.DAMAGE_STATES} id={`${ids.DAMAGE_STATES}NavItem`}>
        <SidebarNavLink to={`${paths.DAMAGE_STATES}`}>
          Damage States
        </SidebarNavLink>
        <Badge section={ids.DAMAGE_STATES} level="error" />
      </NavItem>
      <NavItem key={ids.CONSEQUENCES} id={`${ids.CONSEQUENCES}NavItem`}>
        <SidebarNavLink to={`${paths.CONSEQUENCES}`}>
          Consequences
        </SidebarNavLink>
        <Badge section={ids.CONSEQUENCES} level="error" />
      </NavItem>
      <NavItem key={ids.CONSEQUENCES_REPAIR_COST} id={`${ids.CONSEQUENCES_REPAIR_COST}NavItem`}>
        <SidebarNavLink to={`${paths.CONSEQUENCES}/${paths.REPAIR_COST}`} level={2}>
          Repair Cost
        </SidebarNavLink>
        <Badge section={ids.CONSEQUENCES_REPAIR_COST} level="error" />
      </NavItem>
      <NavItem key={ids.CONSEQUENCES_REPAIR_TIME} id={`${ids.CONSEQUENCES_REPAIR_TIME}NavItem`}>
        <SidebarNavLink to={`${paths.CONSEQUENCES}/${paths.REPAIR_TIME}`} level={2}>
          Repair Time
        </SidebarNavLink>
        <Badge section={ids.CONSEQUENCES_REPAIR_TIME} level="error" />
      </NavItem>
      <NavItem key={ids.CONSEQUENCES_LIFE_SAFETY} id={`${ids.CONSEQUENCES_LIFE_SAFETY}NavItem`}>
        <SidebarNavLink to={`${paths.CONSEQUENCES}/${paths.LIFE_SAFETY}`} level={2}>
          Life Safety
        </SidebarNavLink>
        <Badge section={ids.CONSEQUENCES_LIFE_SAFETY} level="error" />
      </NavItem>
      <NavItem key={ids.CONSEQUENCES_RECOVERY_TIME} id={`${ids.CONSEQUENCES_RECOVERY_TIME}NavItem`}>
        <SidebarNavLink to={`${paths.CONSEQUENCES}/${paths.RECOVERY_TIME}`} level={2}>
          Recovery Time
        </SidebarNavLink>
        <Badge section={ids.CONSEQUENCES_RECOVERY_TIME} level="error" />
      </NavItem>
      <NavItem key={ids.CONSEQUENCES_OTHER} id={`${ids.CONSEQUENCES_OTHER}NavItem`}>
        <SidebarNavLink to={`${paths.CONSEQUENCES}/${paths.OTHER}`} level={2}>
          Other
        </SidebarNavLink>
        <Badge section={ids.CONSEQUENCES_OTHER} level="error" />
      </NavItem>
      <NavItem key={ids.MODEL_USAGE} id={`${ids.MODEL_USAGE}NavItem`}>
        <SidebarNavLink to={`${paths.MODEL_USAGE}`}>
          Model Usage
        </SidebarNavLink>
        <Badge section={ids.MODEL_USAGE} level="error" />
        <Badge section={ids.MODEL_USAGE} level="warning" componentUuid={componentUuid} />
      </NavItem>
    </Nav>
  </Sidebar>
);

export default ComponentSidebar;
