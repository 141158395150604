import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import ValidationItem from '#components/pages/Components/Component/SubNav/Snippet/ValidationItem/ValidationItem.jsx';
import withCustomHandleSubmit
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withCustomHandleSubmit/index.js';
import { selectIsSyncValid } from '#selectors/ui/form/component.js';

const mapStateToProps = (state) => ({
  isValid: selectIsSyncValid(state),
});

export default pipe(
  withCustomHandleSubmit,
  connect(mapStateToProps)
)(ValidationItem);
